<template lang="html">
  <section class="event-view-full grid" v-if="dataContent">
    <router-link  :to="{
        name: 'page-entry',
        params: {
         typeContent: this.typeContentUrl,
          slug: this.dataContent.slug,
        },
      }"
     class="content-new" title="Enlace al evento">
      <div class="box-date">
        <span class="number">{{ currentDateDay(dataContent.start_date) }}</span>
        <span class="month"> {{currentDateMonth(dataContent.start_date)}}</span>
      </div>
      <img :src="dataContent.image.url" :alt="dataContent.image_footer" class="img-new" />
      <div class="info-box">
        <h2 class="title">{{ dataContent.name }}</h2>
        <p class="box-dates">
          <template v-for="theme in dataContent.themes" :key="theme.id">
            <span class="category-tag m-right-10-px">{{theme.name}}</span>
          </template>
          <span class="category-location" v-if="dataContent.zone">
            {{dataContent.zone.name}}</span>
        </p>
        <p class="dest">
          {{ dataContent.header }}
        </p>
      </div>
    </router-link>
  </section>
</template>

<script lang="js">
  import moment from 'moment'
  export default {
    name: 'event-view-full',
    props: ['dataContent','typeContent'],
    mounted() {

    },
    data() {
      return {

      }
    },
    methods: {
      currentDateMonth(date) {
        return moment(date).locale('es').format('MMMM')
      },
      currentDateDay(date) {
        return moment(date).locale('es').format('D')
      },
    },
    computed: {
      typeContentUrl() {
        switch (this.typeContent) {
          case 'entry':
            return 'noticia'
          case 'event':
            return 'evento'
          default:
            return 'multimedia'
        }
      }
    }
  }
</script>

<style scoped lang="scss">
  @import "@/styles/general.scss";
  @import "@/styles/mixins.scss";

  .event-view-full {
    height: auto;

    @include lg {
      min-height: 480px;
    }

    @include xl {
      min-height: 0;
    }

    @include xxl {
      min-height: 0;
    }
  }


  .content-new {
    cursor: pointer;
    text-decoration: none;
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
    height: 100%;
    /* max-height: 300px; */
    position: relative;
    border-radius: 13px;
    margin-bottom: 20px;
    color: $black;

    .category {
      position: absolute;
      left: 0px;
      top: 10px;
      z-index: 2;
      background: $orange;
      font-weight: 900;
      color: white;
      font-size: 16px;
      padding: 5px 15px;
      border-radius: 13px;
    }

    .box-date {
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      flex-direction: column;
      background: $red;
      color: white;
      padding: 10px;
      border-radius: 7px;
      align-items: center;
      min-width: 90px;

      span {
        color: white;
        text-align: center;
        font-family: "Frank Ruhl Libre", serif;
        line-height: 0.8em;

        &.number {
          font-weight: 700;
          font-size: 65px;
        }

        &.month {
          font-size: 18px;
          text-transform: capitalize;
        }
      }
    }

    .img-new {
      width: 100%;
      border-radius: 13px;
      height: auto;
      -o-object-fit: cover;
      object-fit: cover;
      -o-object-position: center;
      object-position: center;
      height: 270px;
    }
  }

  .info-box {
    width: 100%;
    padding: 20px 0px;
  }

  .other-related .grid-3 {
    .subtitle {
      display: none;
    }
  }

  .events .grid-3 {
    .subtitle {
      display: none;
    }

    .content-new .box-date {
      top: 0;
      left: 10px;
    }
  }

  @media screen and (max-width:845px) {
    .content-new .box-date span.number {
      font-weight: 700;
      font-size: 40px;
    }

    .content-new .img-new {
      height: 235px;
    }

    .content-new {
      padding-left: 0;
    }

    .events .grid-3 .content-new .box-date {
      top: 0;
      left: 0;
    }
  }
</style>